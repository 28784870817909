<template>
   <v-form ref="noti-form" @submit.prevent="formSubmit">
      <v-row>
         <v-col cols="12">
            <h3>Custom Notification</h3>
         </v-col>
         <v-col cols="12">
            <v-card>
               <v-card-text>
                  <v-row>
                     <v-col md="6" cols="12">
                        <FormControl inputType="string" v-model="title" label="Notification title" :required="true" />
                     </v-col>
                     <v-col md="6" cols="12">
                        <FormControl inputType="string" v-model="content" label="Notification content" :required="true" />
                     </v-col>
                     <v-col md="6" cols="12">
                        <FormControl inputType="datePicker" v-model="scheduleDate" label="Schedule Date" :required="true" />
                     </v-col>
                     <v-col md="6" cols="12">
                        <FormControl inputType="timePicker" v-model="scheduleTime" label="Schedule Time" :required="true" />
                     </v-col>
                  </v-row>
               </v-card-text>
            </v-card>
         </v-col>
         <v-col cols="12">
            <v-btn color="primary" class="px-8" large :disabled="isFetching" type="submit">Save</v-btn>
         </v-col>
      </v-row>
   </v-form>
</template>

<script>
import dummyData from "@/components/dummy-data"
import FormControl from "@/components/form/FormControl";
import { mapActions, mapState } from "vuex";

export default {
   name: "CustomNoti",
   components: {
   FormControl
   },
   data() {
      return {
         title: '',
         content: '',
         scheduleDate: '',
         scheduleTime: ''
      }
   },
   computed: {
      ...mapState({
         isFetching: (state) => state.request.isFetching,
         isSuccess: (state) => state.request.isSuccess,
         isFail: (state) => state.request.isFail,
      }),
   },
   methods: {
      ...mapActions(["sendRequest"], "request"),
      ...mapActions(["setDialogMessage", "setShowDialog"]),

      async initData() {
         const resp = await this.sendRequest({ url: '', method: 'GET', params: {} });
         if(resp.data) {
            const data = dummyData.push_noti;
            if(data) {
               this.title = data.title;
               this.content = data.content;
               this.scheduleDate = data.schedule_date;
               this.scheduleTime = data.schedule_time;
            }
         }
      },
      async formSubmit() {
         console.log('save');
         const isValid = this.$refs['noti-form'].validate();
         if(isValid) {
            const formdata = {
               id: this.id,
               //...
            };
            const resp = await this.sendRequest({
               url: '', 
               method: 'POST', 
               data: formdata,
            });
            console.log('resp', resp);
            if(resp) {
               this.setDialogMessage({ 
                  message: resp.data ? "Successful" : "Failed", 
                  isError: !resp.data, 
               });
               this.setShowDialog(true);
               await this.initData();
            }
         }
      },
   },
   async mounted() {
      await this.initData();
   }
}
</script>
